import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { SwiperConfigInterface, SwiperDirective } from "ngx-swiper-wrapper";
import { AppService } from "src/app/app.service";
import { ProductZoomComponent } from "src/app/pages/products/product/product-zoom/product-zoom.component";
import { emailValidator } from "src/app/theme/utils/app-validators";
import {
  ProductDto,
  ProductServiceProxy,
  SessionServiceProxy,
  UserLoginInfoDto,
} from "src/shared/service-proxies/service-proxies";

@Component({
  selector: "app-product-poopup",
  templateUrl: "./product-poopup.component.html",
  styleUrls: ["./product-poopup.component.scss"],
})
export class ProductPoopupComponent implements OnInit {
  @ViewChild("zoomViewer", { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  public product: ProductDto;
  public image: any;
  public zoomImage: any;
  private sub: any;
  public form: FormGroup;
  public relatedProducts: Array<ProductDto>;
  myuser: UserLoginInfoDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private _productService: ProductServiceProxy,
    public dialogRef: MatDialogRef<ProductPoopupComponent>,
    public _sessionService: SessionServiceProxy,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.myData.product.additionalInfo = null;
    this._sessionService.getCurrentLoginInformations().subscribe((result) => {
      this.myuser = result.user;
    });
    this.product = this.myData.product;

    console.log(this.myData.product);

    if (this.myData.product.images.length > 0) {
      this.image = this.myData.product.images[0]?.medium;
      this.zoomImage = this.myData.product.images[0]?.big;
    }

    setTimeout(() => {
      this.config.observer = true;
      // this.directiveRef.setIndex(0);
    });

    this.form = this.formBuilder.group({
      review: [null, Validators.required],
      name: [
        null,
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
      email: [null, Validators.compose([Validators.required, emailValidator])],
    });
    this.getRelatedProducts();
  }

  addFunction() {
    if (this.myuser != null || this.myData.isPos == true) {
      this.dialogRef.close(this.myData.product);
    } else {
      this.dialogRef.close();
      this.snackBar.open("You must login first", "×", {
        panelClass: ["error"],
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }
  closeFunction() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.config = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 3,
        },
      },
    };
  }

  public getProductById(id) {

    this._productService.getProduct(id).subscribe((data) => {
      this.product = data;
      if (data.images.length > 0) {
        this.image = data.images[0].medium;
        this.zoomImage = data.images[0].big;
      }

      setTimeout(() => {
        this.config.observer = true;
        // this.directiveRef.setIndex(0);
      });
    });
  }

  public getRelatedProducts() {
    this.appService.getProducts("related").subscribe((data) => {
      this.relatedProducts = data;
    });
  }

  public selectImage(image) {
    this.image = image.medium;
    this.zoomImage = image.big;
  }

  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      var image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = (offsetX / image.offsetWidth) * 100;
      y = (offsetY / image.offsetHeight) * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + "% " + y + "%";
        zoomer.style.display = "block";
        zoomer.style.height = image.height + "px";
        zoomer.style.width = image.width + "px";
      }
    }
  }

  public onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = "none";
  }

  public openZoomViewer() {
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: "zoom-dialog",
    });
  }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      //email sent
    }
  }
}

export interface DialogData {
  product: ProductDto;
  isPos: boolean
}
