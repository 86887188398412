import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from "../../app.service";
import { BillServiceProxy, CartDto } from 'src/shared/service-proxies/service-proxies';


@Component({
  selector: 'app-pick-table',
  templateUrl: './pick-table.component.html',
  styleUrls: ['./pick-table.component.scss']
})
export class PickTableComponent implements OnInit {

  pickedTable;
  isConfirm = false;

  constructor(
    public dialogRef: MatDialogRef<PickTableComponent>,
    public appService: AppService,
    private _billService: BillServiceProxy,
    @Inject(MAT_DIALOG_DATA) public myData: DialogData,) { }

  ngOnInit(): void {
  }

  addFunction(tableNumber) {
    this.pickedTable = tableNumber
  }

  closeFunction(value) {
    this.dialogRef.close(value);
  }

  cancel() {
    this.pickedTable = null;
  }

  confirmClick() {
    var productList: CartDto[] = [];
    this.appService.Data.cartList.forEach(
      eachItem => {
        delete eachItem.id;
        eachItem.product = null;
        eachItem.productDto = null;
        productList.push(eachItem);
      }
    ); this._billService.addTableOrder(this.myData.tenancyName, this.pickedTable, "-", productList).subscribe(
      result => {
        this.isConfirm = true;

      }
    )

  }

}


export interface DialogData {
  tablelist: string[];
  tenancyName: string
}